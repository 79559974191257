import { ClaspEmployeeConfig, ClaspEmployerConfig } from "@/types";
import { LineOfCoverage, MemberRelationship } from "@/types/api";
import { MRT_TableOptions } from "mantine-react-table";

export const DEFAULT_CONFIG: ClaspEmployerConfig = {
  navigation: "state",
  apiClient: "sandbox",
  employerId: "employer_123",
  onNavigate: (to: string) => {
    window.history.pushState({}, "", to);
  },
  links: {
    home: "/",
    editUser: "/",
  },
  auth: () => ({ accessToken: "", expiresAt: "" }),
};

export const DEFAULT_CONFIG_EE: ClaspEmployeeConfig = {
  navigation: "state",
  apiClient: "sandbox",
  employeeId: "ee_123",
  onNavigate: (to: string) => {
    window.history.pushState({}, "", to);
  },
  links: {
    home: "/",
    editUser: "/",
  },
  auth: () => ({ accessToken: "", expiresAt: "" }),
};

export const DEFAULT_TABLE_PROPS: Partial<MRT_TableOptions> = {
  enableTopToolbar: false,
  enableBottomToolbar: false,
  enableColumnActions: false,
  enableSorting: false,
  mantineTableHeadCellProps: {
    p: "xs",
    style: {
      textTransform: "capitalize",
    },
  },
  mantineTableBodyCellProps: {
    p: "xs",
  },
  mantineTableBodyRowProps: {
    style: {
      "&:hover": {
        // backgroundColor: '#F5F5F5'
      },
    },
  },
  mantineSearchTextInputProps: {
    variant: "default",
  },
  initialState: {
    showGlobalFilter: true,
  },
};

export const states = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
] as const;

// Ancillary lines of coverage that are not Medical/Dental/Vision
export const SUPPLEMENTAL_LOCS = [
  "life",
  "accidental_death",
  "voluntary_life",
  "voluntary_critical_illness",
  "accident",
  "short_term_disability",
  "long_term_disability",
  "hospital_indemnity",
] as LineOfCoverage[];

// Lines of coverage that allow the employee to elect certain coverage volumes within a range
export const CUSTOM_VOLUME_LOCS = [
  "voluntary_life",
  "voluntary_critical_illness",
] as const satisfies readonly LineOfCoverage[];

export const ENROLLMENT_REASONS = {
  new_child: "Birth or Adoption",
  dependent_court_order: "Court Order",
  divorce: "Divorce or Legal Separation",
  domestic_partnership: "Domestic Partnership",
  marriage: "Marriage",
  relocated: "Relocated",
  dependent_lost_coverage: "Aging out of parent's coverage",
  lost_coverage: "Loss of Coverage",
  death: "Death of Dependent",
  open_enrollment: "Open Enrollment",
  leave_of_absence: "Leave of Absence",
};

export const DOCUMENT_TYPES = {
  summary_of_benefits_and_coverage: "Summary of Benefits and Coverage",
};

export const PLAN_TYPE = {
  ppo: {
    label: "PPO",
    tooltip:
      "Enables you to see any provider in-network, including specialists, without a referral. Can see out-of-network providers, but at a higher cost than in-network providers.",
    badgeColor: "#00527C",
    badgeBackground: "#E0F0FF",
  },
  hmo: {
    label: "HMO",
    tooltip:
      "Limits coverage to a network of providers in the associated geographic area. Requires you to see an in-network primary care physician first before seeing a specialist.",
    badgeColor: "#5E4200",
    badgeBackground: "#FFD6A4",
  },
  dppo: {
    label: "PPO",
    tooltip:
      "Provides flexibility with more provider choices, includes out-of-network coverage at higher costs.",
    badgeColor: "#00527C",
    badgeBackground: "#E0F0FF",
  },
  dhmo: {
    label: "HMO",
    tooltip:
      "Limits coverage to a network of providers in the associated geographic area.",
    badgeColor: "#5E4200",
    badgeBackground: "#FFD6A4",
  },
  vsp: {
    label: "VSP",
  },
  life: {
    label: "Life",
  },
  accidental_death: {
    label: "AD&D",
  },
  pos: {
    label: "POS",
  },
};

export enum BiologicalSex {
  Male = "male",
  Female = "female",
}
export const BiologicalSexOptions = [
  { value: BiologicalSex.Male, label: "Male" },
  { value: BiologicalSex.Female, label: "Female" },
];
export const PayRateFrequencyOptions = [
  { value: "hour", label: "Hour" },
  { value: "week", label: "Week" },
  { value: "month", label: "Month" },
  { value: "year", label: "Year" },
];
export const PayFrequencyOptions = [
  { value: "biweekly", label: "Bi-weekly" },
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
  { value: "semimonthly", label: "Semi-monthly" },
];
export const EmployeeTypeOptions = [
  { value: "hourly", label: "Hourly" },
  { value: "salaried", label: "Salaried" },
];

export const MemberRelationshipOptions: {
  [Relationship in MemberRelationship]: string;
} = {
  [MemberRelationship.Spouse]: "Spouse",
  [MemberRelationship.DomesticPartner]: "Domestic Partner",
  [MemberRelationship.CivilUnion]: "Civil Union",
  [MemberRelationship.ExSpouse]: "Ex Spouse",
  [MemberRelationship.Child]: "Child",
  [MemberRelationship.Grandchild]: "Grandchild",
  [MemberRelationship.Stepchild]: "Step Child",
  [MemberRelationship.Fosterchild]: "Foster Child",
  [MemberRelationship.Adoptedchild]: "Adopted Child",
  [MemberRelationship.LegalGuardianship]: "Legal Guardianship",
  [MemberRelationship.CourtOrderedDependent]: "Court Ordered Dependent",
  [MemberRelationship.Other]: "Other",
};

export const PartnerRelationshipTypes = [
  MemberRelationship.Spouse,
  MemberRelationship.DomesticPartner,
  MemberRelationship.CivilUnion,
  MemberRelationship.ExSpouse,
];

export const SUPPLEMENTAL_LOC_NAMES = {
  life: "Life",
  accidental_death: "AD&D",
  voluntary_life: "Voluntary Life",
  accident: "Accident",
  short_term_disability: "Short Term Disability",
  long_term_disability: "Long Term Disability",
  voluntary_critical_illness: "Voluntary Critical Illness",
  hospital_indemnity: "Hospital Indemnity",
};

export const PARTNER_LOGO_URLS = {
  Allstate: "https://clasp-public-assets.s3.amazonaws.com/logos/allstate.svg",
  Bennie: "https://clasp-public-assets.s3.amazonaws.com/logos/bennie.png",
};

export const TODAY = new Date(new Date().setHours(0, 0, 0, 0));
