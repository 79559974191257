import { CUSTOM_VOLUME_LOCS, SUPPLEMENTAL_LOC_NAMES } from "@/constants";
import { UsCurrencyFormatter } from "@/helpers/string";
import {
  Accordion,
  Alert,
  Divider,
  Flex,
  Group as MantineGroup,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useAtomValue } from "jotai";
import { useMemo } from "react";
import { memberDependentsAtom, memberInfoAtom } from "../API";
import { DependentsCard } from "../CoverageStep/DependentsCard";
import { PlanCard } from "../CoverageStep/PlanCard";

import { partnerConfigAtom } from "@/stores";
import { Group } from "@/types/api";
import { IconFileCheck } from "@tabler/icons-react";
import { BasicSupplementalBenefitCard } from "../CoverageStep/SupplementalStep/BasicSupplementalBenefitCard";
import { VoluntarySupplementalBenefitCard } from "../CoverageStep/VoluntarySupplementalBenefitCard";
import { WaiveCard } from "../CoverageStep/WaiveCard";
import { useEnrollContext } from "../EnrollProvider";

const CUSTOM_AGREEMENT_PARTNER_IDS = [
  "par_Al5BL4avFGb9KDeiWmzGP",
  "par_GxfKdsVXhZFwSQrkdxYRp",
];
const CUSTOM_AGREEMENT_CARRIER_IDS = ["carr_KXGdSmnZh9gg2euDMeUCb"];

export const EmployeeEnrollFinalReview = () => {
  const dependents = useAtomValue(memberDependentsAtom);
  const memberInfo = useAtomValue(memberInfoAtom[0]);
  const { id: partnerId } = useAtomValue(partnerConfigAtom[0]);

  const {
    state: { medical, dental, vision, supplemental: selectedSupplemental },
  } = useEnrollContext();

  const employeeCost = useMemo(() => {
    let supplementalContributions = 0;
    selectedSupplemental.forEach(
      (planQuote) =>
        (supplementalContributions += Number(
          planQuote?.quote?.per_pay_period_member_contribution || 0,
        )),
    );
    return (
      Number(medical.quote?.per_pay_period_member_contribution || 0) +
      Number(vision.quote?.per_pay_period_member_contribution || 0) +
      Number(dental.quote?.per_pay_period_member_contribution || 0) +
      supplementalContributions
    );
  }, [medical.quote, dental.quote, vision.quote]);
  const employerCost = useMemo(() => {
    let supplementalContributions = 0;
    selectedSupplemental.forEach(
      (planQuote) =>
        (supplementalContributions += Number(
          planQuote?.quote?.per_pay_period_employer_contribution || 0,
        )),
    );
    return (
      Number(medical.quote?.per_pay_period_employer_contribution || 0) +
      Number(vision.quote?.per_pay_period_employer_contribution || 0) +
      Number(dental.quote?.per_pay_period_employer_contribution || 0) +
      supplementalContributions
    );
  }, [medical.quote, dental.quote, vision.quote]);

  const selectedPlans = [
    medical.quote,
    dental.quote,
    vision.quote,
    ...Array.from(selectedSupplemental.values()).map(({ quote }) => quote),
  ];

  const selectedCarrierIds = selectedPlans
    .filter((plan) => !!plan)
    .map(({ plan: { group } }) => (group as Group).carrier as string);

  const showsCustomAgreement =
    CUSTOM_AGREEMENT_PARTNER_IDS.includes(partnerId) &&
    !!selectedCarrierIds.find((carrierId) =>
      CUSTOM_AGREEMENT_CARRIER_IDS.includes(carrierId),
    );

  return (
    <Stack gap={24}>
      <Accordion multiple>
        {medical.quote !== undefined && (
          <Accordion.Item key={"medical"} value="medical">
            <Accordion.Control> Medical </Accordion.Control>
            <Accordion.Panel>
              <Stack gap={24}>
                {medical.quote !== null && (
                  <DependentsCard
                    memberInfo={memberInfo}
                    dependents={dependents}
                    selectedDependents={medical.dependents}
                  />
                )}
                {medical.quote === null ? (
                  <WaiveCard />
                ) : (
                  <PlanCard
                    planQuoteWithDependents={medical}
                    selected={medical.quote}
                  />
                )}
              </Stack>
            </Accordion.Panel>
          </Accordion.Item>
        )}
        {dental.quote !== undefined && (
          <Accordion.Item key={"dental"} value="dental">
            <Accordion.Control> Dental </Accordion.Control>
            <Accordion.Panel>
              <Stack gap={24}>
                {dental.quote !== null && (
                  <DependentsCard
                    memberInfo={memberInfo}
                    dependents={dependents}
                    selectedDependents={dental.dependents}
                  />
                )}
                {dental.quote === null ? (
                  <WaiveCard />
                ) : (
                  <PlanCard
                    planQuoteWithDependents={dental}
                    selected={dental.quote}
                  />
                )}
              </Stack>
            </Accordion.Panel>
          </Accordion.Item>
        )}
        {vision.quote !== undefined && (
          <Accordion.Item key={"vision"} value="vision">
            <Accordion.Control> Vision </Accordion.Control>
            <Accordion.Panel>
              <Stack gap={24}>
                {vision.quote !== null && (
                  <DependentsCard
                    memberInfo={memberInfo}
                    dependents={dependents}
                    selectedDependents={vision.dependents}
                  />
                )}
                {vision.quote === null ? (
                  <WaiveCard />
                ) : (
                  <PlanCard
                    planQuoteWithDependents={vision}
                    selected={vision.quote}
                  />
                )}
              </Stack>
            </Accordion.Panel>
          </Accordion.Item>
        )}
        {Array.from(selectedSupplemental)?.map(([lineOfCoverage, planQuote]) =>
          planQuote.quote ? (
            <Accordion.Item key={lineOfCoverage} value={lineOfCoverage}>
              <Accordion.Control>
                {SUPPLEMENTAL_LOC_NAMES[lineOfCoverage]}
              </Accordion.Control>
              <Accordion.Panel>
                {CUSTOM_VOLUME_LOCS.some((loc) => loc === lineOfCoverage) ? (
                  <VoluntarySupplementalBenefitCard
                    initialPlanQuote={planQuote.quote}
                    selected={selectedSupplemental}
                  />
                ) : (
                  <BasicSupplementalBenefitCard
                    initialPlanQuote={planQuote.quote}
                    selected={selectedSupplemental}
                  />
                )}
              </Accordion.Panel>
            </Accordion.Item>
          ) : (
            <Accordion.Item key={lineOfCoverage} value={lineOfCoverage}>
              <Accordion.Control disabled>
                {SUPPLEMENTAL_LOC_NAMES[lineOfCoverage]} (Waived)
              </Accordion.Control>
            </Accordion.Item>
          ),
        )}
      </Accordion>

      <Flex direction="column" gap={16}>
        <Title size="h1">Your Cost Summary</Title>
        <Divider />
        <MantineGroup justify="space-between">
          <Text fw={600}>Total Costs of Benefits</Text>
          <Text fw={600}>
            {UsCurrencyFormatter.format(employeeCost + employerCost)} per pay
            period
          </Text>
        </MantineGroup>
        <MantineGroup justify="space-between">
          <Text fw={600}>Employer's Contribution</Text>
          <Text fw={600}>
            {UsCurrencyFormatter.format(employerCost)} per pay period
          </Text>
        </MantineGroup>
        <MantineGroup justify="space-between">
          <Text fw={600}>Your Total Costs</Text>
          <Text fw={600}>
            {UsCurrencyFormatter.format(employeeCost)} per pay period
          </Text>
        </MantineGroup>
      </Flex>
      {showsCustomAgreement && (
        <>
          <Alert
            style={{
              borderLeft: 4,
              borderLeftColor: "#F99C04",
              borderLeftStyle: "solid",
            }}
            radius={0}
            vars={() => ({
              root: {
                "--alert-bg": "#FFF4C6",
              },
            })}
            title={
              <Text c="#BC5B11">
                By submitting your enrollments using the submission button
                below, I agree to:
              </Text>
            }
          />
          <Accordion defaultValue="1" bg="#FAFAFA">
            <Accordion.Item value="1">
              <Accordion.Control>
                <MantineGroup>
                  <IconFileCheck size={16} />
                  <Text>
                    KAISER FOUNDATION HEALTH PLAN, INC. ARBITRATION AGREEMENT
                  </Text>
                </MantineGroup>
              </Accordion.Control>
              <Accordion.Panel>
                I understand that (except for Small Claims Court cases, claims
                subject to a Medicare appeals procedure or the ERISA claims
                procedure regulation, and any other claims that cannot be
                subject to binding arbitration under governing law) any dispute
                between myself, my heirs, relatives, or other associated parties
                on the one hand and Kaiser Foundation Health Plan, Inc. (KFHP),
                any contracted health care providers, administrators, or other
                associated parties on the other hand, for alleged violation of
                any duty arising out of or related to membership in KFHP,
                including any claim for medical or hospital malpractice (a claim
                that medical services were unnecessary or unauthorized or were
                improperly, negligently, or incompetently rendered), for
                premises liability, or relating to the coverage for, or delivery
                of, services or items, irrespective of legal theory, must be
                decided by binding arbitration under California law and not by
                lawsuit or resort to court process, except as applicable law
                provides for judicial review of arbitration proceedings. I agree
                to give up our right to a jury trial and accept the use of
                binding arbitration. I understand that the full arbitration
                provision is contained in the Evidence of Coverage.
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </>
      )}
    </Stack>
  );
};
